/* 
 @authors:
 Abdul Wahhab Alfaghiri Al Anzi   01524445
 Nouzad Mohammad                  00820679
*/
import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTemperatureFull,
  faDroplet,
} from "@fortawesome/free-solid-svg-icons";

function Weather() {
  const [temperature, setTemperature] = useState(null);
  const [humidity, setHumidity] = useState(null);
  useEffect(() => {
    const source = new EventSource("http://192.168.137.33");
    source.onmessage = (event) => {
      const data = event.data.split(": ");
      if (data[0] === "Temperature") {
        setTemperature(data[1]);
      } else if (data[0] === "Humidity") {
        setHumidity(data[1]);
      }
    };
    return () => {
      source.close();
    };
  }, []);

  return (
    <div className="timecontainer">
      <Container>
        <div className="col m-0 mb-1">
          <tr>
            <td>
              <FontAwesomeIcon
                icon={faTemperatureFull}
                color="white"
                style={{ height: "4vw" }}
              />
            </td>
            <td>
              <p>{}</p>
            </td>
            <td>
              <h1>
                &nbsp; {temperature}
                <span> &#8451;</span>{" "}
              </h1>
            </td>
            <td>
              <p>&nbsp; &nbsp;</p>
            </td>
            <td>
              <FontAwesomeIcon
                icon={faDroplet}
                color="white"
                style={{ height: "4vw" }}
              />
            </td>
            <td>
              <p>&nbsp;</p>
            </td>
            <td>
              <h1>{humidity} &#37;</h1>
            </td>
            <td></td>
          </tr>
        </div>
      </Container>
    </div>
  );
}
export default Weather;
